import React from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { HOME_PAGE, PRIVACY_PAGE } from "./constants/routes.constant";
import { HomePage } from "./pages/home/home.component";
import { PrivacyPage } from "./pages/privacy/privacy.component";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Redirect exact from="/" to={HOME_PAGE} />
        <Route exact path={HOME_PAGE} component={HomePage} />
        <Route exact path={PRIVACY_PAGE} component={PrivacyPage} />
        <Route path='/' component={HomePage} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
